import styled from 'styled-components';
import { ToastContainer, Slide } from 'react-toastify';
import { CloseOutlineIc } from '@dsch/dd-icons';

import { media } from 'helpers/breakpoints';

interface CloseButtonProps {
  closeToast: () => void;
}

const SToastContainer = styled(ToastContainer).attrs({
  toastClassName: 'toast',
  bodyClassName: 'body',
})`
  width: 100%;
  top: ${({ theme }) => theme.spacing.L56};
  right: ${({ theme }) => theme.spacing.S8};
  display: flex;
  flex-direction: column;
  align-items: center;
  // Ensure minimum gap of 8px on screen sizes that match Notification component width (320px)
  padding: ${({ theme }) => `0 ${theme.spacing.S8}`};
  z-index: 9010;

  @media only screen and (min-width: 336px) {
    padding: 0;
    width: 320px;
    left: auto;
  }

  .toast {
    padding: 0;
    margin: 0;
    min-height: 0;
    border-radius: ${({ theme }) => theme.borderRadius.default};
    background: transparent;
    margin-bottom: ${({ theme }) => theme.spacing.M24};
    width: 100%;
    box-shadow: ${({ theme }) => theme.shadow.CONTAINER};

    @media only screen and (min-width: 336px) {
      width: 320px;
    }
  }

  .body {
    padding: 0;

    @media only screen and (min-width: 336px) {
      padding: 0;
    }
  }

  ${media.large} {
    top: ${({ theme }) => theme.spacing.XL72};
    right: ${({ theme }) => theme.spacing.L48};
  }
`;

const SIcon = styled(CloseOutlineIc)`
  position: absolute;
  top: ${({ theme }) => theme.spacing.S8};
  right: ${({ theme }) => theme.spacing.M16};

  @media only screen and (min-width: 336px) {
    right: ${({ theme }) => theme.spacing.S8};
  }
`;

const CloseButton = ({ closeToast }: CloseButtonProps) => (
  <SIcon
    data-testid="close-toast"
    onClick={closeToast}
    width="16"
    height="16"
  />
);

function Toast() {
  return (
    <SToastContainer
      hideProgressBar
      transition={Slide}
      closeButton={CloseButton}
      newestOnTop
    />
  );
}

export { Toast };
